import axios from 'axios';
import { SERVICE_LAYER_URL } from '../../../config';

class DNBTokenServiceImpl {
  
  constructor() {
    this.storageKey = 'dnb-token';
    this.request = null;
  }
  
  async getToken() {
    let tokenObj = null;
    const tokenObjStr = localStorage.getItem(this.storageKey);
    let needRefreshToken = true;
    if (tokenObjStr) {
      tokenObj = JSON.parse(tokenObjStr);
      needRefreshToken = tokenObj.expirationTime < Date.now();
      if (needRefreshToken) {
        this._deleteToken();
      }
    }
    if (needRefreshToken) {
      tokenObj = await this.setToken();
    }
    return tokenObj.value;
  }

  async setToken() {
    const response = await this._makeRequest();
    const tokenObj = {
      value: response.data.access_token,
      expirationTime: Date.now() + response.data.expiresIn
    }
  
    localStorage.setItem(this.storageKey, JSON.stringify(tokenObj));
    return tokenObj;
  }

  _deleteToken() {
    localStorage.removeItem(this.storageKey);
  }

  async _makeRequest() {
    if (!this.request) {
      this.request = axios(`${SERVICE_LAYER_URL}/marketing/dnb/token`, {
        method: "POST"
      });
    }
    return this.request;
  }
}

const DNBTokenService = new DNBTokenServiceImpl()

export { DNBTokenService }
