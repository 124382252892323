import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from '@reach/router';
import { useLanguage, getLanguageDirection, langPrefix } from './language-context'
import { COUNTRY_LIST, COUNTRY_CODE_MAP } from './countrylist';
import { AnalyticsService, GACategories } from '../services/analytics-service';
import { RTLProximaFallback } from './utils';
import { STATES_LIST, STATES_DEFINITION, countryHasState} from './stateslist';
import { DNBLookupService } from '../services/dnb/dnb-lookup-service';
import { AutoCompletedInput } from './auto-complete-input'
import { InputGroup, Input, InputStatus, InputErrorText, getMandatoryFields, getUtmFields} from './request-form-components';

// images
import checkboxOffSrc from '../images/home/checkbox-off.svg';
import checkboxOnSrc from '../images/home/checkbox-on.svg';
import tickmarkSrc from '../images/home/tick-mark.svg';
import crossmarkSrc from '../images/home/cross-mark.svg';
import { EloquaFormService } from '../services/eloqua/eloqua-form-service';


const FormType = {
  REQUEST_DEMO: 0,
  CONTACT_US: 1
};

const RequestFormStyle = styled.div`
  font-size: 1rem;
`;

const RequestFormLayout = styled.div`
  display: flex; 
  flex-direction: column;
  ${props => props.visible ? '' : 'visibility: hidden;'}

  * {
    font-family: ${RTLProximaFallback};
  }
`;

const NameInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    flex: 1 1 0px;
    min-width: 0px;

    &:first-child {
      margin-inline-end: 40px;
    }
  }
`;

const HelpTextArea = styled.textarea`
  border: 1px solid #000000;
  border-radius: 2px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: 16px;
  padding-inline-end: 32px;
  font-size: 1em;
  resize: none;
`;

const SelectGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
`;

const Select = styled.select`
  ${props => {
    if (props.inputStatus === InputStatus.INIT || !props.inputStatus) {
      return css`background-image: none;`
    }
    if (props.inputStatus === InputStatus.INVALID) {
      return css`background-image: url(${crossmarkSrc});`
    }
    return css`background-image: url(${tickmarkSrc});`
  }};
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: right 4px top 50%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: 16px;
  padding-inline-end: 32px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  border-radius: 0px;
  appearance: none;
  color: transparent;
  
  font-size: 1em;
  line-height: 1.875em;
  box-sizing: border-box;
  ${props => props.fill && css`width: 100%;`};
  position: relative;
  z-index: 2;

  & > option {
    color: #262729;
  }
`;

const SelectValue = styled.div`
  border-bottom: 1px solid #000000;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: 16px;
  padding-inline-end: 32px;
  color: #262729;
  
  font-size: 1em;
  line-height: 1.875em;
  opacity: ${props => props.selected ? 1 : 0.6};
  position: absolute;
  z-index: 1;
`;

const Consent = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ConsentCheckbox = styled.div`
  background-image: url(${props => props.checked ? checkboxOnSrc : checkboxOffSrc});
  background-repeat: no-repeat;
  background-size: 28px 28px;
  width: 28px;
  height: 28px;
  flex: 0 0 28px;
`;

const ConsentCheckboxReal = styled.input`
  margin: 0px;
  width: 28px;
  height: 28px;
  opacity: 0;
`;

const ConsentLabel = styled.label`
  padding: ${props => props.dir === 'rtl' ? '0 24px 0 0' : '0 0 0 24px'};
  font-family: 'Proxima Nova', Lato, sans-serif;
`;

const ButtonConfirm = styled.button`
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  background-color: #78BE20;
  color: #ffffff;
  font-size: 1.125em;
  cursor: pointer;
`;

const ThankYouText = styled.div`
  font-size: 1.5em;
  display: ${props => props.visible ? 'block' : 'none'};
`;

const IndustryList = [
  'Automotive',
  'Business Services',
  'Communications',
  'Construction',
  'Energy',
  'Financial Services',
  'Government',
  'Healthcare',
  'Manufacturing',
  'Media',
  'Mining',
  'Non Profit/Education',
  'Retail',
  'Technology Provider',
  'Travel & Leisure',
  'Utilities',
  'Other'
];

const goToRequestForm = (event, lang, path) => {
  AnalyticsService.trackCustomEvent(event);
  navigate(langPrefix(lang, `/contactus?from=${path}`));
};

const RequestForm = useLanguage(({lang, msg, formType, fromPath}) => {
  const [formData, setFormData] = useState({
    ...getMandatoryFields(),
    comment: '',
    consentChecked: false
  });

  const [completionItems, setCompletionItems] = useState([]);
  const [inputStatus, setInputStatus] = useState(getMandatoryFields(InputStatus.INIT));

  const [submitted, setSubmitted] = useState(false);
  const [currentStateList, setCurrentStateList] = useState(null);
  const [addressHidden, setAddressHidden] = useState(true);
  const [dnbItem, setDnbItem] = useState(null);

  useEffect(() => {
  }, []);

  const toggleConsent = () => {
    setFormData((state) => {
      return {
        ...state,
        consentChecked: !formData.consentChecked
      }
    })
  }

  const buildUtmFields = () => {
    const params = new URLSearchParams(document.location.search.substring(1));
    return getUtmFields().reduce((obj, field) => {
      return {
          ...obj,
          [field]: params.get(field) ?? ''
      };
    }, {});
  }

  const resetCompanyNameAndAddress = () => {
    resetCompanyAddress();
    setCompletionItems([]);
    setCompanyName();
  }

  const updateForm = (event) => {
    const {target} = event;
    const {name, value} = target;
    setFormData((state) => {
      return {
        ...state,
        [name]: value
      };
    });

    // special handling for state and countries
    if (name === 'state') {
      resetCompanyNameAndAddress();
    }
    else if (name === 'country') {
      setFormData((state) => {
        return {
          ...state,
          state: ''
        }
      });
      setAddressHidden(true);
      resetCompanyNameAndAddress();
      if (Object.keys(STATES_LIST).indexOf(value) >= 0) {
        setCurrentStateList(STATES_LIST[value]);
      }
      else {
        setCurrentStateList(null);
      }
    }

    let status;
    if (name === 'email') {
      // const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // status = emailReg.test(value.toLowerCase()) ? status = InputStatus.VALID : InputStatus.INVALID;
      const matching = value.match(new RegExp('@', 'g'));

      status = !matching ? status = InputStatus.INVALID : InputStatus.VALID;
    }
    else {
      status = value === '' ? status = InputStatus.INVALID : InputStatus.VALID;
    }
    setInputStatus((state) => {
      return {
        ...state,
        [name]: status
      };
    });
  };

  const _formatData = () => {
    const utmFields = buildUtmFields();
    let obj = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email.toLowerCase(),
      businessphone: formData.phone,
      jobtitle: formData.jobTitle,
      industry: formData.industry,
      companyname: formData.company,
      companyaddress: formData.address,
      city: formData.city,
      zipcode: formData.zip,
      country: formData.country,
      state: formData.state,
      comment: formData.comment,
      optin: formData.consentChecked ? "on" : "off",
      leadtype: 'Contact Us',
      ...utmFields
    };

    if (dnbItem) {
      obj = {
        ...obj,
        db_companyname: formData.company,
        db_companyaddress1: formData.address,
        db_companyaddress2: '',
        db_city: formData.city,
        db_state: formData.state,
        db_country: formData.country,
        db_zipcode: formData.zip,
        db_companyphone: '',
        db_tradeName: dnbItem.tradeName,
        db_sic: dnbItem.sic,
        db_sicDesc: dnbItem.sicDesc,
        db_revenue: dnbItem.revenue,
        db_duns: dnbItem.duns
      }
    }
    return obj;
  };

  const goToTopOfForm = (selector) => {
    const anchor = document.querySelector(selector);
    if (anchor) {
      const top = anchor.offsetTop;
      const bothHeaderHeight = window.innerWidth > 991 ? 80 : 60;
      window.scrollTo({
        top: top - bothHeaderHeight,
        behavior: 'smooth'
      });
    }
  };

  const submitForm = (event) => {
    let names = Object.keys(inputStatus);
    let errors = {};
    let hasErrors = false;
    
    for (let name of names) {
      if (inputStatus[name] === InputStatus.INIT) {
        if (name !== 'state' || (name === 'state' && countryHasState(formData.country))) {
          errors[name] = InputStatus.INVALID;
          hasErrors = true;
        }
      }
      else if (inputStatus[name] === InputStatus.INVALID) {
        hasErrors = true;
      }
    }
    if (hasErrors) {
      setInputStatus((state) => {
        return {
          ...state,
          ...errors
        };
      });
    }
    else {
      // Everything passed, can submit
      AnalyticsService.trackCustomEvent(event);
      EloquaFormService.submitForm(_formatData());
      if (formType === FormType.REQUEST_DEMO) {
        AnalyticsService.reportConversion('AG7qCJ7MiO0YEMaX-vQD');
        goToTopOfForm('#request-form');
      }
      else if (formType === FormType.CONTACT_US) {
        AnalyticsService.reportConversion('wQc0CMK8jO0YEMaX-vQD');
        goToTopOfForm('#contact-us-form');
      }
      setSubmitted(true);
    }
  };

  const resetCompanyAddress = () => {
    setCompanyAddress();
  }

  const setCompanyAddress = (address = '', city = '', zip = '') => {
    setFormData((state) => {
      return {
        ...state,
        address: address,
        city: city,
        zip: zip
      };
    });

    setInputStatus((state) => {
      return {
        ...state,
        address: address ? InputStatus.VALID : InputStatus.INIT,
        city: city ? InputStatus.VALID : InputStatus.INIT,
        zip: zip ? InputStatus.VALID : InputStatus.INIT
      }
    })
  }

  const autoComplete = async (name) => {
    if (name && name.length > 1 && formData.country && formData.country.length > 0) {
      const companies = await DNBLookupService.getCompanies(name, formData.country, formData.state);
      if (!companies || companies.length === 0) {
        showAddressFields();
        setCompletionItems([]);
      }
      else {
        setCompletionItems(companies);
      }
    }
  }

  const handleChangeCompanyName = async (event) => {
    resetCompanyAddress();
    updateForm(event);
    autoComplete(event.target.value);
  }

  const setCompanyName = (name = '') => {
    setFormData((state) => {
      return {
        ...state,
        company: name
      }
    });
  }

  const handleBlur = (item) => {
    setDnbItem(item);
    if (item) {
      const address = item.address;
      setCompanyName(item.title);
      setCompanyAddress(address.street, address.city, address.zip);
    }
    else {
      showAddressFields();
    }
  }

  const showAddressFields = () => {
    if (addressHidden) {
      setTimeout(() => setAddressHidden(false), 100);
    }
    setDnbItem(null);
    resetCompanyAddress();
  }

  const dropdownLabelArrow = '\u00A0\u00A0\u00A0\u00A0\u25be';

  return (
    <>
      <RequestFormStyle>
        <ThankYouText visible={submitted}>
          {formType === FormType.CONTACT_US && msg('contactus-thanks')}
          {formType === FormType.REQUEST_DEMO && msg('contactus-thanks')}
        </ThankYouText>
        <RequestFormLayout id="request-form" visible={!submitted} dir={getLanguageDirection(lang)}>
          <NameInput dir={getLanguageDirection(lang)}>
            <InputGroup>
              <Input name='firstName' type='text' placeholder={msg('form-first-name')} onChange={updateForm} value={formData.firstName} inputStatus={inputStatus.firstName} />
              {inputStatus.firstName === InputStatus.INVALID && <InputErrorText>First Name is a required field</InputErrorText>}
            </InputGroup>
            <InputGroup>
              <Input name='lastName' type='text' placeholder={msg('form-last-name')} onChange={updateForm} value={formData.lastName} inputStatus={inputStatus.lastName} />
              {inputStatus.lastName === InputStatus.INVALID && <InputErrorText>Last Name is a required field</InputErrorText>}
            </InputGroup>
          </NameInput>
          <InputGroup>
            <Input name='email' type='text' placeholder={msg('form-email')} onChange={updateForm} value={formData.email} inputStatus={inputStatus.email} />
            {inputStatus.email === InputStatus.INVALID && <InputErrorText>A valid email address is required</InputErrorText>}
          </InputGroup>
          <InputGroup>
            <Input name='phone' type='text' placeholder={msg('form-phone')} onChange={updateForm} value={formData.phone} inputStatus={inputStatus.phone}/>
            {inputStatus.phone === InputStatus.INVALID && <InputErrorText>Business Phone is a required field</InputErrorText>}
          </InputGroup>
          <InputGroup>
            <Input name='jobTitle' type='text' placeholder={msg('form-job-title')} onChange={updateForm} value={formData.jobTitle} inputStatus={inputStatus.jobTitle}/>
            {inputStatus.jobTitle === InputStatus.INVALID && <InputErrorText>Job Title is a required field</InputErrorText>}
          </InputGroup>
          <SelectGroup>
            <SelectValue selected={!!formData.industry} >
              {formData.industry ? formData.industry : `${msg('form-industry')}${dropdownLabelArrow}`}
            </SelectValue>
            <Select name='industry' type='text' fill={true} value={formData.industry} onChange={updateForm} inputStatus={inputStatus.industry}>
              <option key='industry_placeholder' value='' selected disabled>{msg('form-industry')}</option>
              {IndustryList.map((industry, i) => (<option key={industry} value={industry}>{industry}</option>))}
            </Select>
              {inputStatus.industry === InputStatus.INVALID && <InputErrorText>Industry is a required field</InputErrorText>}
          </SelectGroup>
          <SelectGroup>
            <SelectValue selected={!!formData.country}>
              {formData.country ? COUNTRY_CODE_MAP[formData.country] : `${msg('form-country')}${dropdownLabelArrow}`}
            </SelectValue>
            <Select name='country' type='text' fill={true} value={formData.country} onChange={updateForm} inputStatus={inputStatus.country}>
              <option key='country_placeholder' value='' selected disabled>{msg('form-country')}</option>
              {COUNTRY_LIST.map((country, i) => (<option key={country.code} value={country.code}>{country.name}</option>))}
            </Select>
            {inputStatus.country === InputStatus.INVALID && <InputErrorText>Country is a required field</InputErrorText>}
          </SelectGroup>
          {currentStateList &&
            <SelectGroup>
              <SelectValue selected={!!formData.state}>
                {formData.state && STATES_DEFINITION[formData.country][formData.state] ? STATES_DEFINITION[formData.country][formData.state] : `${msg('form-state')}${dropdownLabelArrow}`}
              </SelectValue>
              <Select name='state' type='text' fill={true} value={formData.state} onChange={updateForm} inputStatus={inputStatus.state}>
                <option key='state_placeholder' value='' selected disabled>{msg('form-state')}</option>
                {currentStateList.map((state) => (<option key={state.code} value={state.code}>{state.name}</option>))}
              </Select>
              {inputStatus.state === InputStatus.INVALID && <InputErrorText>State is a required field</InputErrorText>}
            </SelectGroup>
          }

          <AutoCompletedInput name='company' value={formData.company} list={completionItems} placeholder={msg('form-company')} inputStatus={inputStatus} 
            requiredFieldText="Company is a required field"
            onBlur={handleBlur}
            onChange={handleChangeCompanyName}
          />
          {!addressHidden &&
            <InputGroup>
              <Input name='address' type='text' placeholder={msg('form-company-address')} onChange={updateForm} value={formData.address} inputStatus={inputStatus.address}/>
              {inputStatus.address === InputStatus.INVALID && <InputErrorText>Company address is a required field</InputErrorText>}
            </InputGroup>
          }
          {!addressHidden &&
            <InputGroup>
              <Input name='city' type='text' placeholder={msg('form-city')} onChange={updateForm} value={formData.city} inputStatus={inputStatus.city}/>
              {inputStatus.city === InputStatus.INVALID && <InputErrorText>City is a required field</InputErrorText>}
            </InputGroup>
          }
          {!addressHidden &&
            <InputGroup>
              <Input name='zip' type='text' placeholder={msg('form-zip-code')} onChange={updateForm} value={formData.zip} inputStatus={inputStatus.zip}/>
              {inputStatus.zip === InputStatus.INVALID && <InputErrorText>Zip/Postal Code is a required field</InputErrorText>}
            </InputGroup>
          }
          <InputGroup>
            <HelpTextArea name='comment' placeholder={msg('form-help-comment')} rows='3' onChange={updateForm} value={formData.comment} />
          </InputGroup>
          <Consent>
            <ConsentCheckbox checked={formData.consentChecked}>
              <ConsentCheckboxReal id='request-demo-consent' type='checkbox' onClick={toggleConsent} />
            </ConsentCheckbox>
            <ConsentLabel htmlFor='request-demo-consent' dir={getLanguageDirection(lang)}>
              {msg('form-check-box-agreement')}
            </ConsentLabel>
          </Consent>
          <ButtonConfirm onClick={submitForm}
            data-ec={formType === FormType.REQUEST_DEMO ? GACategories.HomePage : GACategories.ContactUsPage}
            data-ea='Contact Us'
            data-el='Contact Us'>
            {formType === FormType.REQUEST_DEMO && msg('home-demo')}
            {formType === FormType.CONTACT_US && msg('contact-us')}
          </ButtonConfirm>
        </RequestFormLayout>
      </RequestFormStyle>
    </>
  )
})

export { RequestForm, FormType, goToRequestForm }
