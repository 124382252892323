/*
    Service that stores some properties about headers, in order to get them out of header and mobile header components. 
*/

import { ComponentProperty } from "./component-property";

class HeaderServiceImpl extends ComponentProperty {
    
    MINIFIED_HEIGHT = 60;
    EXPANDED_HEIGHT = 80;

    constructor(height = 80) {
        super(height);
    }

}

const HeaderService = new HeaderServiceImpl();

export { HeaderService };
