function mkEventEffect(eName, eHandler) {
  return () => {
    // add when mounted
    window.addEventListener(eName, eHandler)
    return () => {
        // return function to be called when unmounted
      window.removeEventListener(eName, eHandler)
    }
  }
}

function RTLProximaFallback(props) {
  const ProximaFonts = '"Proxima Nova",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
  if (props.dir === 'rtl') {
    return `"Droid Kufi", Almarai, ${ProximaFonts}`;
  }
  return ProximaFonts;
}

function RTLLatoFallback(props) {
  const LatoFonts = "Lato, Arial, sans-serif";
  if (props.dir === 'rtl') {
    return `"Droid Kufi", Almarai, ${LatoFonts}`;
  }
  return LatoFonts;
}


function setCookie (cookieModel = {}) {
  const days = 3650;
  const domain = '.feedbacknow.com';
  const domainString = document.location.href.indexOf(domain) > -1 ? domain : '';
  const date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));
  const expires = "; expires=" + date.toUTCString();
  document.cookie = cookieModel.name + cookieModel.value + expires + (domainString ? '; domain=' + domainString : '') + "; path=/";
}


function checkCookie (cookieModel = {}) {
  let hasCookie = false;
  if (document.cookie.length > 0) {
      let offset = document.cookie.indexOf(cookieModel.name);
      if (offset > -1) {
          offset += cookieModel.name.length;
          // set index of beginning of value
          var end = document.cookie.indexOf(";", offset);
          // set index of end of cookie value
          if (end === -1) {
          end = document.cookie.length;
          }
          const value = parseInt(unescape(document.cookie.substring(offset, end)));
          if (value === cookieModel.value) {
              hasCookie = true;
          }
      }
  }
  return hasCookie;
}

function createCookieModel(name) {
  return {
    name: name + '=',
    value: 2
  }
}

const toCapitalCase = (str) => {
  let result = '';
  if (typeof str === 'string') {
    result = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return result;
}

const capitalize = (str) => {
  let result = '';
  if (str.indexOf("-") > -1) {
    result = str.split("-").map(str => toCapitalCase(str)).join("-");
  }
  else {
    result = toCapitalCase(str);
  }
  return result;
}

const capitalizeWords = (text) => {
  return text.split(" ").map(str => capitalize(str)).join(" ");
}




export { mkEventEffect, RTLProximaFallback, RTLLatoFallback, checkCookie, setCookie, createCookieModel, capitalize, capitalizeWords};
