import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import fontFiles from '../fonts/fonts';
import { Footer } from './footer'
import { Header } from './header'
import { useLanguage, LanguageStorageKey, changeLanguage, getLanguageDirection, getLanguageDirectionWithException, getLanguageFontSize } from './language-context';
import { FbnCookieConsent } from './cookie-consent';
import { mkEventEffect, RTLLatoFallback } from './utils';
import { Helmet } from 'react-helmet';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${props => getLanguageFontSize(props.lang)};
    scroll-padding-top: 180px; /* height of sticky header */
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: normal;
    src: url(${fontFiles.ProximaNovaWOFF2}) format('woff2'),
          url(${fontFiles.ProximaNovaTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: semibold;
    src: local('Proxima Nova'),
          url(${fontFiles.ProximaNovaSemiboldTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: bold;
    src: local('Proxima Nova'),
          url(${fontFiles.ProximaNovaBoldWOFF2}) format('woff2');
  }

  @font-face {
    font-family: 'Droid Kufi';
    font-weight: 400;
    font-style: normal;
    src: url(${fontFiles.DroidKufiTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Droid Kufi';
    font-weight: 700;
    font-style: bold;
    src: local('Droid Kufi'),
          url(${fontFiles.DroidKufiBoldTTF}) format('truetype');
  }

  * {
    font-family: ${RTLLatoFallback};
    direction: ${props => props.dir};
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    margin: 0 auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
  }

  p {
    margin: 0;
    padding: 0;
  }

  h1 {
    margin: 0;
    padding: 0;
  }
`

/* deal with mobile views */
const ContentWrapper = styled.div`
  margin-top: ${props => props.minified ? (props.bannerHidden ? '60px' : '160px') : (props.bannerHidden ? '80px' : '180px')};

  * {
    font-family: ${RTLLatoFallback};
    direction: ${props => props.dir};
  }
`

const needChangeLang = (currentLang) => {
  let needChange = false;
  const langToUse = localStorage.getItem(LanguageStorageKey);
  if (langToUse) {
    needChange = langToUse !== currentLang;
  }
  return [langToUse, needChange];
}

const Layout = useLanguage(({ lang, location, children, onBannerVisibilityChanged }) => {
  const url = location ? location.pathname : '';

  const [minified, setMinified] = useState(false);
  const [bannerHidden, setBannerHidden] = useState(true);

  const handleScroll = () => {
    let headerMinified = false;
    if (window.scrollY > 50) {
      headerMinified = true;
    }
    setMinified(headerMinified);
  }

  const handleBannerHidden = (hidden) => {
    setBannerHidden(hidden);
    onBannerVisibilityChanged && onBannerVisibilityChanged(hidden);
  }

  useEffect(mkEventEffect("scroll", handleScroll), []);
  useEffect(() => {
    const [newLanguage, needChange] = needChangeLang(lang);
    if (needChange) {
      changeLanguage(lang, newLanguage, url);
    }
  });

  return (
    <>
      <Helmet>
        <html lang={lang} dir={getLanguageDirection(lang)} />
        <title>FeedbackNow</title>
      </Helmet>

      <GlobalStyle lang={lang} dir={getLanguageDirection(lang)} />
      <Header url={url} />
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KG9D8XJ" height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe>
      </noscript>
      <ContentWrapper bannerHidden={bannerHidden} minified={minified} dir={getLanguageDirectionWithException(url, lang)}>
        {children}
      </ContentWrapper>
      <FbnCookieConsent />
      <Footer dir={getLanguageDirection(lang)} />
    </>
  )
});

export { Layout }
