/*
Page hits are always tracked by Google Analytics.
GA provides some categories and actions to track user interaction,
but for those they don't have we just use custom events.
See: https://developers.google.com/analytics/devguides/collection/gtagjs/events

AnalyticsService.trackCustomEvent is always passed event and then we can use
that to pull out the data-attribute attached to the targeted element.
My thought was that it would save us from having to write a different
"click handling" function to pass in the set of event_category, event_action
and event_label assigned to each interaction.

In cases where there is already an onClick event attached, I just attach
the data-attributes and put AnalyticsService.trackCustomEvent inside the existing
function and passing through the event if necessary.
*/

import { GOOGLE_TAG_ID } from "../../config"

const GACategories = {
  HomePage: "Home Page",
  ContactUsPage: "Contact Us Page",
  Navigation: "Navigation",
  Passenger: "Passenger",
  Customer: "Customer",
  Employee: "Employee",
  Patient: "Patient",
  Student: "Student",
  MeasureCustomerFeedback: "Instantly Measure",
  AnalyzeFeedback: "Identify Trends and Disruptions",
  DeliverCXImpact: "Take Action and Optimize Operations",
  Banners: "Banners",
  PlatformMeasure: "Platform Measure",
}

class AnalyticsServiceImpl {
  reportConversion(code) {
    if (
      typeof window !== "undefined" &&
      window.gtag !== undefined
    ) {
      console.log('>> report conversion called and window.gtag is defined')
      window.gtag("event", "conversion", {
        send_to: `${GOOGLE_TAG_ID}/${code}`,
      })
    }
  }

  trackCustomEvent(event) {
    const { currentTarget } = event
    if (
      typeof window !== "undefined" &&
      window.gtag !== undefined &&
      currentTarget.dataset["ea"]
    ) {
      window.gtag("event", currentTarget.dataset["ea"], {
        event_category: currentTarget.dataset["ec"],
        event_label: currentTarget.dataset["el"],
      })
    }
  }

  invokeCustomTracking(category, action, label) {
    if (typeof window !== "undefined" && window.gtag !== undefined) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
      })
    }
  }
}

const AnalyticsService = new AnalyticsServiceImpl()

export { AnalyticsService, GACategories }
