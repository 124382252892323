import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { LanguageSelector } from './language-selector'
import { useLanguage, langPrefix, getLanguageDirection } from './language-context'
import { media } from '../styles/media-query-template';
import { goToRequestForm } from './request-form';
import { AnalyticsService, GACategories } from '../services/analytics-service';

import { MobileHeader } from '../components/mobile-header';

// import logo from '../images/forrester-feedback-now-logo-horizontal-color-rgb-01@2x.png'
import logo from '../images/feedbacknow-logo-white.svg';
import dropDownTriangle from '../images/drop-down-triangle.svg';
import dropDownTriangleWhite from '../images/drop-down-triangle-white.svg';
import { mkEventEffect, RTLProximaFallback } from './utils';
import { HeaderService } from '../services/header-service'

const HeaderStyle = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.height}px;
  transition: height .3s;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: ${props => props.bannerHeight}px;
  width: 100%;
  background: #1f2d54;
  z-index: 9999;
  font-size: 1rem;
  
  * {
    font-family: ${RTLProximaFallback};
  }

  *[dir='rtl'] & {
    font-size: 0.85rem;
  }

  ${media.min991`
    display: flex;
  `}

  ${media.large`
    padding-left: 60px;
    padding-right: 60px;
  `}

  ${media.min1550`
    padding-left: 80px;
    padding-right: 80px;
  `}
`;

const HeaderNavMain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderNavAux = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const Logo = styled.img`
  ${props => !props.minified && css`padding-bottom: 6px;`};
  transition: padding .3s;
  padding: ${props => props.dir === 'rtl' ? '0 0 0 20px' : '0 20px 0 0'};
  width: calc(100% - 20px);
`;

const LogoLink = styled(Link)`
  width: 305px;
`;

const Selection = styled(Link)`
  margin: 0 4px;
  padding: 0 4px;
  line-height: ${props => props.minified === true ? '50px' : '70px'};
  white-space: nowrap;
  transition: line-height .3s;
  border-top: 5px solid #1f2d54;
  border-bottom: 5px solid #1f2d54;
  cursor: pointer;
  text-decoration: none;
  color: white;
`

const Selector = styled.div`
  margin: 0 4px;
  padding: 0 4px;
  line-height: ${props => props.minified === true ? '50px' : '70px'};
  transition: line-height .3s;
  border-top: 5px solid #1f2d54;
  border-bottom: 5px solid #1f2d54;
  cursor: pointer;
  color: white;
  position: relative;
`;

const InnerSelector = styled.div``;

// SERIOUSLY???
// cf: https://www.evandromacedo.com/active-nav-links-in-many-routes-with-gatsby-and-styled-components/

const checkIndex = ({ isCurrent }) => {
  if (isCurrent) {
    return { style: {borderBottom: '5px solid white'} }
  }
  return null
}

const checkOffering = (lang) => ({ location }) => {
  if (location.pathname.startsWith(langPrefix(lang, '/offering/'))) {
    return { style: {borderBottom: '5px solid white'} }
  }
  return null
}

/*
const checkSolutions = (lang) => ({ location }) => {
  if (location.pathname.startsWith(langPrefix(lang, '/solutions/'))) {
    return { style: {borderBottom: '5px solid white'} }
  }
  return null
}
*/

const HomeLink = ({ children, oper, ...props }) => {
  return (<LogoLink getProps={oper} {...props}>
    {children}
  </LogoLink>)
};

const SelectionLink = ({ children, oper, ...props }) => {
 return (
  <Selection getProps={oper} {...props}>
      {children}
    </Selection>
  )};

const Overlay = styled.div`
  margin-top: 5px;
  position: absolute;
  width: 300px;
  z-index: 100;
  border: 1px solid #888888;
  display: flex;
  flex-direction: column;
  background: white;
`;

const InnerDropDownList = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: #1f2d54;
  padding: 0 20px;
  border-top: 1px solid #1f2d54;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background-color: #1f2d54;
    color: white;
  }
`;

const PlatformOptionLink = styled(OptionLink)`
  border-top: none;
  line-height: 50px;
`

const TitleOptionLink = styled(PlatformOptionLink)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
`;

const InnerSelectionDropdown = styled.div`
  padding: 0 20px;
  cursor: pointer;
  line-height: 50px;
  text-decoration: none;
  background-color: #1f2d54;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;

  &:first-child {
    border-top: none;
  }

  &::after {
    content: url(${dropDownTriangleWhite});
    margin-left: 8px;
    padding-bottom: 3px;
  }

  &:hover {
    background-color: white;
    color: #1f2d54;

    &::after {
      content: url(${dropDownTriangle});
    }
  }
`;

const SelectionDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropDownIcon = styled.img`
  margin-inline-start: 4px;
`;

const HeaderButton = styled.button`
  padding: 0px 20px;
  font-size: 1em;
  border-radius: 2px;
  cursor: pointer;
  white-space: nowrap;
`;

const ContactUsButton = styled(HeaderButton)`
  height: 38px;
  border: 1px solid white;
  background-color: white;
  color: #1f2d54;
  font-weight: 600;
`;

const LoginButton = styled(HeaderButton)`
  height: 38px;
  border: 1px solid white;
  background-color: #ffffff;
  color: #1f2d54;
`;

const SelectionSelector = ({ children, oper, url, lang, text, minified, urlPartActive }) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  const toggle = () => { setIsOpen(!isOpen); };
  const handleClick = (event) => {
    if (node.current && node.current.contains(event.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(mkEventEffect("mousedown", handleClick));
  const style = url.startsWith(langPrefix(lang, urlPartActive)) ? { borderBottom: '5px solid white'} : null
  return (
      <Selector minified={minified} style={style} ref={node}>
        <SelectionDropdown onClick={toggle}>{text} <DropDownIcon src={dropDownTriangleWhite} /></SelectionDropdown>
        { isOpen && <Overlay>{children}</Overlay> }
      </Selector>
  )
}

const InnerSelectionSelector = ({ children, text, minified }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = (event) => { setIsOpen(!isOpen); };
  return (
    <InnerSelector minified={minified}>
      <InnerSelectionDropdown onClick={toggle}>{text}</InnerSelectionDropdown>
      { isOpen && <InnerDropDownList>{children}</InnerDropDownList> }
    </InnerSelector>
  );
};

const goToLogin = (event) => {
  AnalyticsService.trackCustomEvent(event);
  window.location.href='https://secure.feedback-now.ch/auth/signin';
};

export const solutionOptions = [
  ['https://explore.feedbacknow.com/cx-airports-c/', 'solution-air-travel', 'Air Travel'],
  ['https://explore.feedbacknow.com/cleaning/', 'solution-cleaning', 'Cleaning'],
  ['https://explore.feedbacknow.com/cstore-operations/', 'solution-convenience-retail', 'Convenience and Retail'],
  ['https://explore.feedbacknow.com/cx-healthcare/', 'solution-healthcare', 'Healthcare'],
];


export const Header = useLanguage(({lang, msg, url, onBannerVisibilityChanged}) => {
  const [minified, setMinified] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(HeaderService.EXPANDED_HEIGHT);
  const [bannerHeight, setBannerHeight] = useState(0);

  const handleScroll = () => {
    let headerMinified = false;
    if (window.scrollY > 50) {
      headerMinified = true;
      setHeight(HeaderService.MINIFIED_HEIGHT);
    }
    else {
      setHeight(HeaderService.EXPANDED_HEIGHT);
    }
    setMinified(headerMinified);
  }

  const setHeight = (height) => {
    HeaderService.setHeight(height);
    setHeaderHeight(height);
  }

  const handleBannerVisibilityChanged = (hidden, height) => {
    onBannerVisibilityChanged && onBannerVisibilityChanged(hidden);
    setBannerHeight(height);
  }

  useEffect(mkEventEffect("scroll", handleScroll), []);
  return (
    <>
      <HeaderStyle bannerHeight={bannerHeight} height={headerHeight} minified={minified} dir={getLanguageDirection(lang)}>
        <HeaderNavMain>
          <HomeLink to={langPrefix(lang, '/')} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='FeedbackNow Logo' data-el='FeedbackNow Logo'><Logo minified={minified} src={logo} dir={getLanguageDirection(lang)} /></HomeLink>
        
          <SelectionSelector minified={minified} url={url} lang={lang} text={msg('header-solutions')} urlPartActive={'/solutions/'}>
            { solutionOptions.map((option) => (<OptionLink to={option[0]} key={option[1]} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='Solutions' data-el={option[2]}>{msg(option[1])}</OptionLink>)) }
          </SelectionSelector>
          <SelectionSelector minified={minified} url={url} lang={lang} text={msg('header-product-services')} urlPartActive={'/product-services/'}>
            <PlatformOptionLink to={langPrefix(lang, '/product-services/sentiment')} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='FeedbackNow Measure' data-el='Sentiment'>{ msg('platform-dropdown-measure-sentiment') }</PlatformOptionLink>
            <PlatformOptionLink to={langPrefix(lang, '/product-services/operations')} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='FeedbackNow Measure' data-el='Operations'>{ msg('platform-dropdown-measure-operations') }</PlatformOptionLink>
            <PlatformOptionLink to={langPrefix(lang, '/product-services/customizablebuttons')} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='FeedbackNow Measure' data-el='Custom'>{ msg('platform-dropdown-measure-custom') }</PlatformOptionLink>
            <PlatformOptionLink to={'https://explore.feedbacknow.com/services/'} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='FeedbackNow Measure' data-el='services'>{ msg('platform-dropdown-measure-services') }</PlatformOptionLink>
          </SelectionSelector>
          <SelectionSelector minified={minified} url={url} lang={lang} text={msg('header-platform')} urlPartActive={'/platform/'}>
            <OptionLink to={langPrefix(lang, '/platform/insights')} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea={ msg('platform-dropdown-insights-title')} data-el={ msg('platform-dropdown-insights-title')}>{ msg('platform-dropdown-insights-title') }</OptionLink>
            <OptionLink to={langPrefix(lang, '/platform/alerts')} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='FeedbackNow Improve' data-el='FeedbackNow Improve'>{ msg('platform-dropdown-alerts-title') }</OptionLink>
          </SelectionSelector>
          <SelectionLink target="_blank" minified={minified} to={'https://explore.feedbacknow.com/blog/'} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='Blog' data-el='Blog'>{msg('header-blog')}</SelectionLink>
        </HeaderNavMain>
        <HeaderNavAux>
          <ContactUsButton onClick={(ev) => goToRequestForm(ev, lang, url)} data-ec={GACategories.Navigation} data-ea='Contact Us' data-el='Contact Us'>{msg('contact-us')}</ContactUsButton>
          <LanguageSelector url={url} minified={minified}/>
          <LoginButton onClick={goToLogin} data-ec={GACategories.Navigation} data-ea='Login' data-el='Login'>{msg('header-login')}</LoginButton>
        </HeaderNavAux>
      </HeaderStyle>
      <MobileHeader url={url} bannerHeight={bannerHeight} headerHeight={headerHeight}/>
    </>
  )
})

