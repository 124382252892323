import ProximaNovaWOFF2 from './ProximaNova-Regular.woff2';
import ProximaNovaBoldWOFF2 from './ProximaNova-Bold.woff2';

import ProximaNovaTTF from './ProximaNova-Regular.ttf';
import ProximaNovaSemiboldTTF from './ProximaNova-Semibold.ttf';

import ProximaNovaItalicTTF from './ProximaNova-RegularIt.ttf';
import ProximaNovaSemiboldItalicTTF from './ProximaNova-SemiboldIt.ttf';

import DroidKufiTTF from './DroidKufi-Regular.ttf';
import DroidKufiBoldTTF from './DroidKufi-Bold.ttf';

export default {
  // Proxima Nova
  ProximaNovaWOFF2,
  ProximaNovaBoldWOFF2,
  ProximaNovaTTF,
  ProximaNovaSemiboldTTF,
  ProximaNovaItalicTTF,
  ProximaNovaSemiboldItalicTTF,
  // Droid Kufi
  DroidKufiTTF,
  DroidKufiBoldTTF
};