import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../styles/media-query-template';
import { Link } from 'gatsby';
import { useLanguage, langPrefix, getLanguageDirection } from './language-context';
import fbnFooterLogo from '../images/fbn-footer-logo.svg';
import linkedinLogo from '../images/LinkedIn-logo.svg';
import twitterLogo from '../images/Twitter-logo.svg';
import vsbdots from '../images/tap-dots.svg';
import { FbnModal } from './fbn-modal-component';
import { solutionOptions } from './header';

const FooterStyle = styled.div`
  padding: 40px 30px;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;

  ${media.large`
    padding: 32px 135px 56px;
  `}

  ${media.min1550`
    padding: 32px 135px 56px;
  `}
`;

const FooterSection = styled.div`
  width: 100%;
  max-width: 1440px;
`;

const FooterLinksSection = styled(FooterSection)`
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;

  ${media.medium`
    padding-bottom: 80px;
    flex-direction: row;
    align-items: baseline;
  `}
`;

const FooterLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;

  ${media.medium`
    flex: 1 1 auto;
    margin-inline-end: 24px;
    margin-bottom: 0px;
  `}

  &:last-child {
    margin-inline-end: 0px;
  }
`;

const FooterLinksColumnHeading = styled.h4`
  color: #FFFFFF;
`;

const FooterLink = styled(Link)`
  font-size: 0.75em;
  color: #cfcfcf;
  text-decoration: none;

  
`;

const FooterExternalLink = styled.a`
  font-size: 0.75em;
  color: #cfcfcf;
  text-decoration: none;
`;

const FooterInfoSection = styled(FooterSection)`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  font-size: 0.75em;

  & > * {
    margin-bottom: 24px;
  }
`;

const FooterCopyrightSection = styled.div`
  margin-inline-end: 24px;
  flex: 1 1 592px;

  ${media.medium`
    flex-basis: 512px;
  `}
`;

const SocialMediaSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > a {
    &:first-child {
      margin-inline-end: 32px;
    }
  }
`;

const SocialMediaIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const SmallTextLink = styled.a`
  color: #c1f3df;
  text-decoration: none;
`;

const SmallLink = styled(Link)`
  color: #c1f3df;
  text-decoration: none;
`

const VSBDots = styled.img`
  margin-bottom: -2px;
  height: 14px;
`;



const VSBIframe = styled.iframe`
  border: none;
  height: 450px;
  width: 100%;
  ${media.large`
    width: 800px;
  `}
`;


const Footer = useLanguage(({lang, msg, url}) => {
  const [vsbModalIsOpen, setVsbModalIsOpen] = useState(false);

  const toggleModal = () => {
    setVsbModalIsOpen(!vsbModalIsOpen);
  }
  return (
    <FooterStyle dir={getLanguageDirection(lang)}>
      <FbnModal isOpen={vsbModalIsOpen} contentLabel="Help Us Improve" onClose={toggleModal}>
        <VSBIframe src={msg('footer-modal-vsb-link')} />
      </FbnModal>
      <FooterLinksSection>
        <FooterLinksColumn>
          <img src={fbnFooterLogo} alt={msg('footer-fbn-logo-alt')} />
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterLinksColumnHeading>{msg('header-solutions')}</FooterLinksColumnHeading>
          { solutionOptions.map((option) => (<FooterLink to={option[0]}>{msg(option[1])}</FooterLink>)) }

        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterLinksColumnHeading>{msg('header-product-services')}</FooterLinksColumnHeading>
          <FooterLink to={langPrefix(lang, '/product-services/sentiment')}>{msg('platform-dropdown-measure-sentiment')}</FooterLink>
          <FooterLink to={langPrefix(lang, '/product-services/operations')}>{msg('platform-dropdown-measure-operations')}</FooterLink>
          <FooterLink to={langPrefix(lang, '/product-services/customizablebuttons')}>{msg('platform-dropdown-measure-custom')}</FooterLink>
          <FooterLink to={'https://explore.feedbacknow.com/services/'}>{msg('platform-dropdown-measure-services')}</FooterLink>
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterLinksColumnHeading>{msg('footer-platform')}</FooterLinksColumnHeading>
          <FooterLink to={langPrefix(lang, '/platform/insights')}>{msg('platform-dropdown-insights-title')}</FooterLink>
          <FooterLink to={langPrefix(lang, '/platform/alerts')}>{msg('platform-dropdown-alerts-title')}</FooterLink>
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterLinksColumnHeading onClick={toggleModal} style={{cursor: 'pointer'}}>
            {msg('footer-help-improve')} <VSBDots alt={msg('footer-help-improve')} src={vsbdots} />
          </FooterLinksColumnHeading>
          <FooterLink to={langPrefix(lang, '/request-demo')}>{msg('home-demo')}</FooterLink>
          <FooterLink to={langPrefix(lang, '/contactus')}>{msg('contact-us')}</FooterLink>
          <FooterLink to={'https://go.forrester.com/policies/#statement'} target="_blank">{msg('footer-uk-slavery-statement')}</FooterLink>
        </FooterLinksColumn>
      </FooterLinksSection>
      <FooterInfoSection>
        <FooterCopyrightSection>
          <p style={{marginBottom: '8px'}}>{msg('footer-copyright')}</p>
          <p>
            {msg('footer-purchase-agreement')} <SmallLink to={langPrefix(lang, '/term-and-conditions')}>{msg('footer-general-tnc')}</SmallLink> | <SmallTextLink target='_blank' rel='noopener noreferrer' href='https://go.forrester.com/policies/privacy-policy/'>{msg('footer-privacy-policy')}</SmallTextLink> | <SmallTextLink target='_blank' rel='noopener noreferrer' href='https://go.forrester.com/policies/terms-of-use/'>{msg('footer-terms-of-use')}</SmallTextLink>
          </p>
        </FooterCopyrightSection>
        <SocialMediaSection>
          <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/forrester'><SocialMediaIcon src={twitterLogo} alt='twitter logo' /></a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/company/forrester-research/'><SocialMediaIcon src={linkedinLogo} alt='linkedin logo' /></a>
        </SocialMediaSection>
      </FooterInfoSection>
    </FooterStyle>
  );
});

export { Footer }
