export class ComponentProperty {
    _height;

    constructor(height = 0) {
        this._height = height;
    }

    getHeight() {
        return this._height;
    }

    setHeight(height = 0) {
        this._height = height;
    }
}
