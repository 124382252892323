const STATES_DEFINITION = {
    "AU": {
        "ACT": "Australian Capital Territory",
        "NSW": "New South Wales",
        "NT": "Northern Territory",
        "QLD": "Queensland",
        "SA": "South Australia",
        "TAS": "Tasmania",
        "VIC": "Victoria",
        "WA": "Western Australia"
    },
    "CA": {
        "AB": "Alberta",
        "BC": "British Columbia",
        "MB": "Manitoba",
        "NB": "New Brunswick",
        "NL": "Newfoundland and Labrador",
        "NT": "Northwest Territories",
        "NS": "Nova Scotia",
        "NU": "Nunavut",
        "ON": "Ontario",
        "PE": "Prince Edward Island",
        "QC": "Quebec",
        "SK": "Saskatchewan",
        "YT": "Yukon Territory"
    },
    "US": {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District of Columbia",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming",
        "AS": "American Samoa",
        "GU": "Guam Gu",
        "MP": "Northern Mariana Islands",
        "PR": "Puerto Rico",
        "VI": "Virgin Islands",
        "AA": "Armed Forces Americas",
        "AFA": "Armed Forces Africa",
        "AC": "Armed Forces Canada",
        "AE": "Armed Forces Europe",
        "AM": "Armed Forces Middle East",
        "AP": "Armed Forces Pacific"
    }
}

const STATES_LIST = Object.keys(STATES_DEFINITION).reduce((obj, countryCode) => {
    obj[countryCode] = Object.keys(STATES_DEFINITION[countryCode]).map(stateCode => {
        return {
            code: stateCode,
            name: STATES_DEFINITION[countryCode][stateCode]
        }
    });
    return obj;
}, {});

const getStateCode = (country, stateName) => {
    let stateCode = '';
    if (country && STATES_LIST[country]) {
        const state = STATES_LIST[country].find(state => state.name.toLowerCase() === stateName.toLowerCase().trim());
        if (state) {
            stateCode = state.code;
        }
    }
    return stateCode;
}

const countryHasState = (country) => {
    return Object.keys(STATES_DEFINITION).indexOf(country) > -1;
}

export { STATES_LIST, STATES_DEFINITION, getStateCode, countryHasState};