import React, {createContext} from 'react'
import { navigate } from 'gatsby'

const LanguageContext = createContext()

/*
const SimpleLanguageContext = createContext()

const withLanguageContext = Comp => {
  const Lang = (props) => { 
    return (
      <SimpleLanguageContext.Consumer>
        { lang => <Comp {...props} lang={lang}  />}
      </SimpleLanguageContext.Consumer>
    )
  }
  return Lang
}

const provideLanguage = (lang, Comp) => (props) => {
  return (
    <SimpleLanguageContext.Provider value={lang}>
      <Comp {...props} />
    </SimpleLanguageContext.Provider>
  )      
}  
*/


const withLanguage = (lang, msg, Comp) => (props) => {
  return (
    <LanguageContext.Provider value={{lang: lang, msg: msg}}>
      <Comp {...props} />
    </LanguageContext.Provider>
  )      
}

const useLanguage = (Comp) => (props) => {
  const msg = (obj) => {
    return ((key) => {
      if (obj.msg[key]) {
        return obj.msg[key];
      }
      return obj.msg.DEFAULT[key] ? obj.msg.DEFAULT[key] : '?'+ key + '(' + obj.lang + ')';
    });
  };
  return (
    <LanguageContext.Consumer>
      { obj => <Comp {...props} lang={obj.lang} msg={msg(obj)} />}
    </LanguageContext.Consumer>
  )
}

const passLanguage = (Comp) => (props) => {
  const pc = props.pageContext
  const Result = withLanguage(pc.lang, pc.msg, Comp)
  return <Result {...props} />
}

const switchPrefix = (o_lang, n_lang, url) => {
  // code to switch a URL
  if (url.startsWith(`/${o_lang}`)) {
    if (n_lang === 'en') {
      return url.slice(1 + o_lang.length)
    }
    else {
      return `/${n_lang}` + url.slice(1 + o_lang.length)
    }
  }
  else {
    if (n_lang === 'en') {
      return url
    }
    else {
      return `/${n_lang}` + url
    }
  }
}

const langPrefix = (lang, path) => {
  return lang === 'en' ? path : `/${lang}${path}`
}

const saveLang = (lang) => {
  localStorage.setItem(LanguageStorageKey, lang);
}

const changeLanguage = (lang, code, url, needSave) => {
  navigate(switchPrefix(lang, code, url));
  if (needSave !== false) {
    saveLang(code);
  }
}

const ccedil = '\xe7';
const ntilde = '\xf1';
const ecirc = '\xea';

const LanguageNames = {
  ar: 'عربى',
  de: 'Deutsch',
  en: 'English',
  es: `Espa${ntilde}ol`,
  fr: `Fran${ccedil}ais`,
  it: 'Italiano',
};

const LanguageStorageKey = 'useLanguage';

const LanguageProperties = {
  ar: { dir: `rtl`, fontSize: `20px` },
  de: { dir: `ltr`, fontSize: `16px` },
  en: { dir: `ltr`, fontSize: `16px` },
  es: { dir: `ltr`, fontSize: `16px` },
  fr: { dir: `ltr`, fontSize: `16px` },
  it: { dir: `ltr`, fontSize: `16px` }
};

const getLanguageDirection = (lang) => LanguageProperties[lang].dir;

const getLanguageDirectionWithException = (url, lang) => {
  const exceptionList = [
    // /\/platform\//
  ];

  for (let regx of exceptionList) {
    if (regx.test(url)) {
      return getLanguageDirection('en');
    }
  }
  return getLanguageDirection(lang);
};

const getLanguageFontSize = (lang) => LanguageProperties[lang].fontSize;

export {
  switchPrefix,
  langPrefix,
  withLanguage,
  useLanguage,
  passLanguage,
  LanguageNames,
  changeLanguage,
  LanguageStorageKey,
  getLanguageDirection,
  getLanguageDirectionWithException,
  getLanguageFontSize
}
