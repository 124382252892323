class DNBSettingsImpl {
  constructor() {
    this.host = "plus.dnb.com"
    this.endpoints = {
      token: "/v2/token",
      companies: "/v1/search/typeahead",
    }
    this.authKey =
      "Basic aHVXS091d1I3QnB6Vm5hUFNWNTlCSmdueTMwZU9lamY6MVZ2OUFHVDJDNThRakhSUQ=="
  }

  buildUrl(endpoint) {
    return "https://" + this.host + endpoint
  }
}

const DNBSettings = new DNBSettingsImpl()

export { DNBSettings }
