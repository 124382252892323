import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { useLanguage, langPrefix, LanguageNames, changeLanguage, getLanguageDirection } from './language-context';
import { media } from '../styles/media-query-template';
import { AnalyticsService, GACategories } from '../services/analytics-service';

import logo from '../images/feedbacknow-logo-white.svg';
import hamburgerBarSrc from '../images/hamburger-bar.svg';
import menuCloseXSrc from '../images/menu-close-x.svg';
import menuArrowRightSrc from '../images/angle-down-white.svg';
import dropDownTriangleWhite from '../images/drop-down-triangle-white.svg';
import { mkEventEffect, RTLProximaFallback } from './utils';
import { HeaderService } from '../services/header-service';
import { solutionOptions } from './header';


const MobileHeaderContainer = styled.div`
  font-size: 1rem;

  ${media.min991`
    display: none;
  `}
`;

const MobileHeaderStyle = styled.div`
  width: 100%;
  font-family: ${RTLProximaFallback};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${props => props.bannerHeight}px;
  height: ${props => props.height}px;
  z-index: 9999;

  * {
    font-family: ${RTLProximaFallback};
  }
`;

const TopBanner = styled.div`
  padding: 0px 30px;
  background-color: #1f2d54;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HamburgerButton = styled.div`
  padding: 8px;
  border-bottom: 5px solid white;
  font-size: 0.75em;
  box-sizing: border-box;
  transition: height 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: ${props => props.height}px;
`;

const HamburgerBarIcon = styled.img`
  margin: 4px 0px;
  height: 20px;
`;

const TopBannerLogoArea = styled.div`
  padding-inline-start: 16px;
  flex: 1 0 auto;
`;

const MainDropDownOverlay = styled.div`
  width: 100%;
  height: calc(100vh - ${props => props.height}px);
  background-color: #FFFFFF;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 100%;
`;

const MainMenuContainer = styled.div`
  background-color: #000000;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1 0 auto;
  position: relative;
`;

const Menu = styled.div`
  box-sizing: border-box;
  padding: 16px;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
`;

const MainMenu = styled(Menu)`
  width: 100%;
  position: absolute;
  left: ${props => props.show ? '0px' : '-100%'};
  top: 0px;
  transition: left 0.4s;
`;

const SubMenu = styled(Menu)`
  width: 100%;
  position: absolute;
  left: ${props => props.show ? '0px' : '100%'};
  top: 0px;
  transition: left 0.4s;
`;

const MenuRow = styled.div`
  padding: 12px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MenuHeading = styled(MenuRow)`
  font-size: 1.25em;
  font-weight: 600;
  cursor: default;
`;

const MenuSelectorRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuSelectorTitle = styled.div`
  padding: 12px 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;

  &::after {
    content: url(${dropDownTriangleWhite});
    margin-left: 8px;
    margin-bottom: 3px;
  }
`;

const MenuSelectorDropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

const MenuRowLink = styled(Link)`
  width: 100%;
  color: #FFFFFF;
  text-decoration: none;
`;

const MenuRowTitleLink = styled(MenuRowLink)`
  text-transform: uppercase;
  font-weight: bold;
`;

const MenuSection = styled.div`
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 12px;
`;

const MenuBack = styled(MenuSection)`
  font-size: 16px;
  line-height: 4rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MenuBackArrow = styled.span`
  margin-inline-end: 8px;
  font-size: 2rem;
`;

const MenuCloserSection = styled.div`
  padding: 24px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 0.75em;
  flex: 0 0 0px;
`;

const MenuCloser = styled.div`
  cursor: pointer;
`;

const MenuCloseX = styled.img`
  margin-bottom: 4px;
  width: 24px;
  height: 24px;
`;

const MenuLinkExternal = styled.a`
  color: #FFFFFF;
  text-decoration: none;
  ${props => props.fill && css`flex: 1 0 0px;`};
`;

const Icon = styled.div`
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
`;

const IconArrowRight = styled(Icon)`
  background-image: url(${menuArrowRightSrc});
  background-size: 0.85rem;
  transform: rotate(-90deg);
`;

const MenuTitle = styled.span`
  color: white;
`

const MenuSelector = ({ children, titleText }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => { setIsOpen(!isOpen) };
  return (
    <MenuSelectorRow>
      <MenuSelectorTitle onClick={toggle}>{titleText}</MenuSelectorTitle>
      {isOpen && <MenuSelectorDropDown>{children}</MenuSelectorDropDown>}
    </MenuSelectorRow>
  );
};


const MobileHeader = useLanguage(({lang, msg, url, bannerHeight, headerHeight}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState({
    main: true,
    solutions: false,
    language: false,
    platform: false,
    productServices: false
  });

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const openSubMenu = (event, name) => {
    setShowSubMenu((state) => {
      return {
        ...state,
        main: false,
        [name]: true
      };
    })
  };

  const closeSubMenu = (event) => {
    setShowSubMenu((state) => {
      return {
        ...state,
        main: true,
        solutions: false,
        language: false,
        platform: false,
        productServices: false
      };
    })
  };

  const switchLanguage = (event, code) => {
    changeLanguage(lang, code, url);
  };

  return (
    <MobileHeaderContainer>
      <MobileHeaderStyle bannerHeight={bannerHeight} isOpen={isOpen} dir={getLanguageDirection(lang)}>
        <TopBanner>
          <HamburgerButton onClick={toggleMenu} height={headerHeight}>
            <HamburgerBarIcon src={hamburgerBarSrc} />
            <MenuTitle>{msg('header-menu')}</MenuTitle>
          </HamburgerButton>
          <TopBannerLogoArea>
            <Link to={langPrefix(lang, '/')} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='FeedbackNow Logo' data-el='FeedbackNow Logo'>
              <img src={logo} alt='Forrester FeedbackNow'/>
            </Link>
          </TopBannerLogoArea>
        </TopBanner>
        { isOpen &&
          <MainDropDownOverlay height={headerHeight}>
            <MainMenuContainer>
              <MainMenu show={showSubMenu.main}>
                <MenuHeading>
                  {msg('header-main-menu')}
                </MenuHeading>
                <MenuSection>
                  <MenuRow onClick={(ev) => openSubMenu(ev, 'solutions')}>
                    {msg('header-solutions')}<IconArrowRight />
                  </MenuRow>
                  <MenuRow onClick={(ev) => openSubMenu(ev, 'productServices')}>
                    {msg('header-product-services')}<IconArrowRight />
                  </MenuRow>
                  <MenuRow onClick={(ev) => openSubMenu(ev, 'platform')}>
                    {msg('header-platform')}<IconArrowRight />
                  </MenuRow>
                  <MenuRow>
                    <MenuLinkExternal href='https://explore.feedbacknow.com/blog/' target="_blank" onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='Blog' data-el='Blog'>{msg('header-blog')}</MenuLinkExternal>
                  </MenuRow>
                </MenuSection>
                <MenuSection>
                  <MenuRow>
                    <MenuRowLink to={langPrefix(lang, `/contactus?from=${url}`)} onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='Contact Us' data-el='Contact Us'>{msg('contact-us')}</MenuRowLink>
                  </MenuRow>
                  <MenuRow onClick={(ev) => openSubMenu(ev, 'language')}>
                    {msg('header-language')}<IconArrowRight />
                  </MenuRow>
                  <MenuRow>
                    <MenuLinkExternal href='https://secure.feedback-now.ch/auth/signin' fill='true' onClick={AnalyticsService.trackCustomEvent} data-ec={GACategories.Navigation} data-ea='Login' data-el='Login'>{msg('header-login')}</MenuLinkExternal>
                  </MenuRow>
                </MenuSection>
              </MainMenu>
              <SubMenu show={showSubMenu.solutions}>
                <MenuBack onClick={closeSubMenu}>
                  <MenuBackArrow>&larr;</MenuBackArrow>
                  {msg('header-back-to-main')}
                </MenuBack>
                <MenuHeading>
                  {msg('header-solutions')}
                </MenuHeading>
                <MenuSection>
                  {
                    solutionOptions.map((option) => (
                      <MenuRow>
                        <MenuRowLink
                          to={option[0]}
                          key={option[1]}
                          onClick={AnalyticsService.trackCustomEvent}
                          data-ec={GACategories.Navigation}
                          data-ea='Solutions'
                          data-el={option[2]}>{msg(option[1])}</MenuRowLink>
                      </MenuRow>
                    ))
                  }
                </MenuSection>
              </SubMenu>
              <SubMenu show={showSubMenu.productServices}>
                <MenuBack onClick={closeSubMenu}>
                  <MenuBackArrow>&larr;</MenuBackArrow>
                  {msg('header-back-to-main')}
                </MenuBack>
                <MenuHeading>
                  {msg('header-product-services')}
                </MenuHeading>
                <MenuSection>
                  <MenuRow>
                    <MenuRowLink to={langPrefix(lang, '/product-services/sentiment')}>{ msg('platform-dropdown-measure-sentiment') }</MenuRowLink>
                  </MenuRow>
                  <MenuRow>
                    <MenuRowLink to={langPrefix(lang, '/product-services/operations')}>{ msg('platform-dropdown-measure-operations') }</MenuRowLink>
                  </MenuRow>
                  <MenuRow>
                    <MenuRowLink to={langPrefix(lang, '/product-services/customizablebuttons')}>{ msg('platform-dropdown-measure-custom') }</MenuRowLink>
                  </MenuRow>
                  <MenuRow>
                    <MenuRowLink to={'https://explore.feedbacknow.com/services/'}>{ msg('platform-dropdown-measure-services') }</MenuRowLink>
                  </MenuRow>
                </MenuSection>

              </SubMenu>
              <SubMenu show={showSubMenu.platform}>
                <MenuBack onClick={closeSubMenu}>
                  <MenuBackArrow>&larr;</MenuBackArrow>
                  {msg('header-back-to-main')}
                </MenuBack>
                <MenuHeading>
                  {msg('header-platform')}
                </MenuHeading>
                <MenuSection>
                  <MenuRow>
                    <MenuRowLink to={langPrefix(lang, '/platform/insights')}>{ msg('platform-dropdown-insights-title') }</MenuRowLink>
                  </MenuRow>
                  <MenuRow>
                    <MenuRowLink to={langPrefix(lang, '/platform/alerts')}>{ msg('platform-dropdown-alerts-title') }</MenuRowLink>
                  </MenuRow>
                </MenuSection>
              </SubMenu>
              <SubMenu show={showSubMenu.language}>
                <MenuBack onClick={closeSubMenu}>
                  <MenuBackArrow>&larr;</MenuBackArrow>
                  {msg('header-back-to-main')}
                </MenuBack>
                <MenuHeading>
                  {msg('header-language')}
                </MenuHeading>
                <MenuSection>
                  {Object.keys(LanguageNames).map((code) => (<MenuRow key={code} onClick={(event) => switchLanguage(event, code)}>{LanguageNames[code]}</MenuRow>))}
                </MenuSection>
              </SubMenu>
            </MainMenuContainer>
            <MenuCloserSection>
              <MenuCloser onClick={toggleMenu}>
                <MenuCloseX src={menuCloseXSrc} /><br/>
                {msg('header-close')}
              </MenuCloser>
            </MenuCloserSection>
          </MainDropDownOverlay>
        }
      </MobileHeaderStyle>
    </MobileHeaderContainer>
  );
});

export { MobileHeader };
