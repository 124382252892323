import styled, { css } from 'styled-components';
import tickmarkSrc from '../images/home/tick-mark.svg';
import crossmarkSrc from '../images/home/cross-mark.svg';

const InputGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  ${props => {
    if (props.inputStatus === InputStatus.INIT || !props.inputStatus) {
      return css`background-image: none;`
    }
    if (props.inputStatus === InputStatus.INVALID) {
      return css`background-image: url(${crossmarkSrc});`
    }
    return css`background-image: url(${tickmarkSrc});`
  }};
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: right 4px top 50%;
  border: none;
  border-bottom: 1px solid #000000;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: 16px;
  padding-inline-end: 32px;
  line-height: 1.875em;
  
  font-size: 1em;

  &::placeholder {
    color: #262729;
    opacity: 0.6;
  }

  *[dir='rtl'] & {
    background-position: left 4px top 50%;
  }
`;

const InputErrorText = styled.div`
  padding: 4px 16px 0px;
  
  font-size: 0.75em;
  color: #ca020f;
`;

const InputStatus = {
  INIT: 0,
  VALID: 1,
  INVALID: 2
};

const getMandatoryFields = (defaultValue = '') => {
  return ['firstName', 'lastName', 'email', 'phone', 'jobTitle', 'industry', 'company', 'address', 'city', 'zip', 'country', 'state'].reduce((prev, field) => {
    return {
      ...prev,
      [field]: defaultValue
    };
  }, {});
}

const getUtmFields = () => {
  return ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term','utm_content'];
}

export { Input, InputGroup, InputStatus, InputErrorText, getMandatoryFields, getUtmFields };