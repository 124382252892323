import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import closeModalBtn from '../images/modal-close.svg';
import { media, mediaWidths } from '../styles/media-query-template';
import { mkEventEffect } from './utils';

const getModalStyle = (isMobile, width, height, noOverflow) => {
  return {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      zIndex: "99",
      overflowY: 'scroll'
    },
    content: {
      margin: "auto",
      top: "180px",
      left: "0px",
      bottom: "auto",
      right: "0px",
      backgroundColor: "#FFFFFF",
      width: width ? width : 'calc(100% - 32px)',
      maxWidth: width ? width : '800px',
      height: height ? height : "455px",
      padding: isMobile ? "0px" : "20px",
      overflow: noOverflow ? "none" : "auto",
    },
  }
}

const CloseVSBModalBtn = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 24px;
  width: 24px;
  ${media.large`
    height: 13px;
    width: 13px;
  `}
  cursor: pointer;
`

const FbnModal = ({isOpen, children, contentLabel, onClose, hideOnMobileView = false, width, height, noOverflow = false}) => {
  const [isMobileView, setIsMobileView] = useState(false)

  const toggleModal = () => {
    toggleIsMobile()
    onClose && onClose()
  }

  const toggleIsMobile = () => {
    setIsMobileView(window.innerWidth < mediaWidths.large)
  }

  const handleResize = () => {
    if (isOpen) {
      toggleIsMobile()
    }
  }

  useEffect(() => {
    handleResize();
  });
  useEffect(mkEventEffect("resize", handleResize), []);

  return (
    <Modal
      isOpen={isOpen && (hideOnMobileView && isMobileView ? false : true)}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={true}
      style={getModalStyle(isMobileView, width, height, noOverflow)}
    >
      <CloseVSBModalBtn onClick={toggleModal} src={closeModalBtn} />
      {children}
    </Modal>
  )
}

export { FbnModal }
