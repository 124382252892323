import {css} from 'styled-components';

/*
Mobile is anything less than 768 pixels 
Intermediate/Tablet is 769-1199px pixels
Desktop is anything above 1200px
*/

const mediaWidths = {
  min1550: 1550,
  large: 1200,
  min991: 991,
  medium: 768,
};

/*
Setup for Media Query Template. See Styled Components docs for more information.
https://medium.com/@samuelresua/easy-media-queries-in-styled-components-690b78f50053
NOTE: Mobile-first design therefore using min-width.
*/
const media = Object.keys(mediaWidths).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${mediaWidths[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export {media, mediaWidths};