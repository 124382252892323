import axios from 'axios';
import { DNBTokenService } from './dnb-token-manager-service';
import { DNBSettings } from './dnb-settings';
import { getStateCode } from '../../components/stateslist';
import { capitalizeWords } from '../../components/utils';

class DBLookupServiceImpl {

  async getCompanies(name, country, state = '') {
    let result = [];
    let response = null;
    const token = await DNBTokenService.getToken();
    const params = {
      searchTerm: name,
      countryISOAlpha2Code: country,
      isOutOfBusiness: false
    }

    if (state) {
      params['addressRegion'] = state;
    }
    const paramsStr = Object.keys(params).map(key => {
      const paramsValue = params[key];
      const value = typeof paramsValue == 'string' ? paramsValue.trim() : paramsValue;
      return key + '=' + encodeURIComponent(value);
    }).join('&');
    
    const url = DNBSettings.buildUrl(DNBSettings.endpoints.companies) + '?' + paramsStr;

    try {
      response = await axios(url, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${token}`,
        }
      });
    }
    finally {
      if (response?.data?.searchCandidates) {
        result = response.data.searchCandidates.map(company => {
          const dnbCountry = this._getProperty(company, 'addressCountry');
          const city = this._getProperty(company, 'addressLocality');
          const region = this._getProperty(company, 'addressRegion');
          const street = this._getProperty(company, 'streetAddress');
          const zip = this._getProperty(company, 'postalCode', false);
          const stateCode = getStateCode(country, region);
          const stateAndZip = (stateCode ? stateCode + " " : "") + zip;
          const organization = company.organization;
          return {
            title: capitalizeWords(company.organization.primaryName),
            address: {
              country: dnbCountry,
              city: city,
              state: stateCode,
              street: street,
              zip: zip,
            },
            duns: company.organization.duns,
            tradeName: organization.tradeStyleNames ? organization.tradeStyleNames.map(tradeName => tradeName.name).join(" ") : '',
            revenue: organization.financials ? organization.financials[0].yearlyRevenue[0].value + " " + organization.financials[0].yearlyRevenue[0].currency : '0',
            sic: organization.primaryIndustryCodes ? organization.primaryIndustryCodes[0].usSicV4 : '',
            sicDesc: organization.primaryIndustryCodes ? organization.primaryIndustryCodes[0].usSicV4Description : '',
            subtitle: [street.toLowerCase(), city.toLowerCase(), stateAndZip].filter(item => item.length > 0).join(', '),
          };
        });
      }
      return result;
    }

  }

  _getProperty(company, prop, removeKeys = true) {
    const property = company.organization.primaryAddress[prop];
    let result = '';
    if (property) {
      if (removeKeys) {
        result = Object.keys(property).reduce((carry, key) => {
          return carry + ' ' + property[key];
        }, '');
      }
      else {
        result = property;
      }
    }

    return result;
  }
  
}

const DNBLookupService = new DBLookupServiceImpl()

export { DNBLookupService }
