import React from 'react';

const getHighlightedText = (text, highlight) => {
  
  const highlightPieces = highlight.split(" ").map(h => h.toLowerCase()).map(h => escapeRegExp(h));
  const parts = text.split(new RegExp(`(${highlightPieces.join("|")})`, 'gi')).filter(str => str.length);
 
  return <span> { parts.map((part, i) => 
      <span key={i} className={highlightPieces.indexOf(part.toLowerCase()) >= 0 ? 'match' : 'no-match'}>
          { part }
      </span>)
  } </span>;
}

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
export { getHighlightedText };