import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLanguage, LanguageNames, changeLanguage } from './language-context'

import dropDownTriangleWhite from '../images/drop-down-triangle-white.svg';
import { mkEventEffect } from './utils';

const Select = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  color: white;
  
  font-size: 1rem;
  line-height: 80px;
  cursor: pointer;
  position: relative;

  & > * {
    margin-inline-start: 4px;

    &:first-child {
      margin-inline-start: 0px;
    }
  }

  *[dir='rtl'] & {
    font-size: 0.85rem;
  }
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 100;
  top: ${props => props.minified === true ? '70px' : '80px'};
  transition: top .3s;
  left: 0px;
  border: 1px solid #1f2d54;
  display: flex;
  flex-direction: column;
  background: #1f2d54;
`;

const LangButton = styled.button`
  border: none;
  color: #1f2d54;
  border-top: 1px solid #888888;
  background-color: #FFFFFF;
  padding: ${props => props.minified === true ? '11px 16px' : '16px'};
  transition: padding .3s;
  font-size: 1em;
  text-align: start;
  cursor: pointer;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background-color: #1f2d54;
    color: #ffffff;
  }
`;

const LanguageSelector = useLanguage(({lang, url, msg, minified}) => {
  const [value, setValue] = useState(lang);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => { setIsOpen(!isOpen) };
  const node = useRef();
  const handleClick = (event) => {
    if (node.current && node.current.contains(event.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(mkEventEffect("mousedown", handleClick), []);
  const onClick = (evt) => {
    setValue(evt.target.value);
    changeLanguage(lang, evt.target.value, url);
  };
  return (
    <Select onClick={toggle} ref={node}>
      <span>{LanguageNames[value]}</span><img src={dropDownTriangleWhite} alt={msg('language-selector-alt-image')} />
      {
        isOpen &&
        <Overlay minified={minified}>
          {Object.keys(LanguageNames).map((code) => (<LangButton key={code} value={code} minified={minified} onClick={onClick}>{LanguageNames[code]}</LangButton>))}
        </Overlay>
      }
    </Select>
  )
})

export { LanguageSelector }
