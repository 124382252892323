import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { Input, InputGroup, InputStatus, InputErrorText} from './request-form-components'
import { getHighlightedText } from './text-highlighter'
import { mkEventEffect } from './utils'

const KeyCodes = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  TAB: 9
}

const Ul = styled.ul`
  background: white;
  border: 1px solid black;
  z-index: 10000;
  list-style: none;
  max-height: 500px;
  z-index: 999;
  left: 0px;
  overflow: auto;
  box-shadow: 0px 5px 8px 0px rgb(0 0 0 / 20%);
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
`

const Li = styled.li`
  &:hover {
    background: #f4f4f4;
    cursor: pointer;
  }
  ${props => props.selected && css`background: #e4e4e4 !important;`}
  padding: 5px;
  
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const Title = styled.div`
  font-size: 0.938rem;
  & .match {
    font-weight: bold;
  }

  & .no-match {
    font-weight: normal;
  }
`

const Subtitle = styled.div`
  font-size: 0.625rem;
  text-transform: capitalize;
`

const ExtandedInputGroup = styled(InputGroup)`
  position: relative;
`


const AutoCompletedInput = ({ name, value, placeholder, list, onBlur, onChange, inputStatus, requiredFieldText }) => {
  const node = useRef()

  const [isOpen, setIsOpen] = useState(false);
  const [currentLine, setCurrentLine] = useState(0);
  const [chosenFromList, setChosenFromList] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleClick = event => {
    // click inside
    if (node.current && node.current.contains(event.target)) {
      return;
    }
    else {
      //click outside
      if (focused && !chosenFromList) {
        handleBlur();
      }
    }
  }

  const handleFocus = () => {
    setFocused(true);
    setIsOpen(true);
  }

  const handleBlur = (item = null) => {
    setChosenFromList(item !== null);
    setIsOpen(false);
    setFocused(false);
    onBlur && onBlur(item);
  }

  const handleKeyDown = (event) => {
    const { keyCode } = event;

    switch (keyCode) {
      case KeyCodes.UP:
        if (currentLine > 0) {
          setCurrentLine(currentLine - 1);
        }
        break;
      case KeyCodes.DOWN:
        if (currentLine + 1 < list.length) {
          setCurrentLine(currentLine + 1);
        }
        break;
      case KeyCodes.ENTER:
        if (currentLine >= 0 && currentLine < list.length) {
          handleBlur(list[currentLine]);
        }
        break;
      case KeyCodes.TAB:
        handleBlur();
        break;
    }
  }

  const handleChange = (event) => {
    onChange && onChange(event);
    setCurrentLine(0);
    setIsOpen(true);
    setChosenFromList(false);
    setFocused(true);
  }

  useEffect(mkEventEffect("mousedown", handleClick))

  return (
    <ExtandedInputGroup ref={node}>
      <Input autoComplete='new-password' name={name} type='text' inputStatus={inputStatus[name]} placeholder={placeholder} value={value} onChange={handleChange} onKeyDown={handleKeyDown} onFocus={handleFocus} />
      {list.length > 0 && isOpen &&
        <Ul>
          {list.map((item, i) =>
            <Li key={'company-' + i} onClick={() => handleBlur(item)} selected={currentLine === i}>
              <Title>
                {getHighlightedText(item.title, value)}
               </Title>
              <Subtitle>{item.subtitle}</Subtitle>
            </Li>
          )}
        </Ul>
      }
      {(!isOpen || list.length === 0) && inputStatus[name] === InputStatus.INVALID && <InputErrorText>{requiredFieldText}</InputErrorText>}
    </ExtandedInputGroup>
  )
}

export { AutoCompletedInput }
