import React, { useState, useEffect } from 'react';
import { useLanguage, getLanguageDirection } from './language-context';
import styled from 'styled-components';
import { RTLProximaFallback, setCookie, checkCookie, createCookieModel } from './utils';

const CookieConsent = styled.div`
    transition: all .5s !important;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: #515961;
    padding: 30px 0;
    width: 100%;
    & *{
        transition: all .5s !important; 
        font-family: ${RTLProximaFallback};
        color: white;
    }
`

const Row = styled.p`
    font-size: 1.125rem;
    margin: 1em 0;
    line-height: 1.4;
    font-weight: 400;
    text-align: center;
    
`

const Link = styled.a`
    font-weight: 700 !important;
    text-decoration: underline;
    &:hover{
        background: #ffe502;
    }
`

const cookieModel = createCookieModel('forrcookieconsent');

const FbnCookieConsent = useLanguage(({lang, msg, url}) => {
    const [hidden, setHidden] = useState(true);
    const handleClick = (e) => {
        e.preventDefault();
        setCookie(cookieModel);
        setHidden(true);
    }

    useEffect(() => {
        const hasCookie = checkCookie(cookieModel);
        setHidden(hasCookie);
    }, []);

    return (
        <CookieConsent hidden={hidden} dir={getLanguageDirection(lang)} data-nosnippet>
            <Row>
                {msg('cookie-consent-text-1')}<Link href="https://go.forrester.com/policies/privacy-policy/#cookie" target="_blank">{msg('cookie-consent-link-1')}</Link>{msg('cookie-consent-text-2')}.
            </Row>
            <Row>
                {msg('cookie-consent-text-3')}<Link href="//www.forrester.com/trust/cookies" target="_blank">{msg('cookie-consent-link-2')}</Link>.
            </Row>
            <Row>
                <Link href="#" id="accept-cookies-btn" onClick={handleClick}>{msg('cookie-consent-accept')}</Link>
            </Row>
        </CookieConsent>
    );
});

export { FbnCookieConsent };