class EloquaFormServiceImpl {
  async submitForm(data) {
    return await this._sendFormData({
      method: "POST",
      data,
    });
  }

  _prepareForEloqua(data) {
    return {
      ...data,
      uniquecode: data.email + "-" + (new Date().getTime()),
      eloquahtmlformname: "",
      elqCustomerGUID: "",
      elqCookieWrite: 0,
      elqFormName: process.env.ELOQUA_FORM_NAME,
      elqSiteID: process.env.ELOQUA_SITE_ID,
      scid: process.env.ELOQUA_SCID,
      notes: "Prospect requested to be contacted, please follow up in 24 hours.",
      formsource: document.location.href,
      src: "FeedbackNow", // see if we need to keep it or provide something more accurate like FBN Mkt website or other
      leadcontext: "FeedBackNow",
      comments: '',
      formcomments: data.comment
    }
  }

  _buildFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    });

    return formData;
  }

  async _sendFormData(params) {
    const preparedData = this._prepareForEloqua(params.data);
    const formData = this._buildFormData(preparedData);
    let errorMessage = null;
    try {
      let response = await fetch(process.env.ELOQUA_SERVICE_URL, {
        method: params.method,
        body: formData,
      });
      if (response.status < 299) {
        return response.status;
      }
      errorMessage = response.status.toString();
    }
    catch (error) {
      if (error.response && error.response.status) {
        errorMessage = error.response.status.toString()
      }
      else {
        errorMessage = error.message;
      }
      console.log(errorMessage);
    }
  }
}

const EloquaFormService = new EloquaFormServiceImpl();

export { EloquaFormService };
